import React from "react";
import { Box } from "@mui/material";

export default function TimeProgressBar({ estimatedTime, spentTime }) {

  const percentage = estimatedTime > 0 ? (spentTime / estimatedTime) * 100 : 0;


  return (
    <Box sx={{ width: "100%", margin:"10px 0px"}}>
      <Box
        sx={{
          width: "100%",
          height: 5,
          backgroundColor: "#c5ead7",
          borderRadius: 5,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: `${percentage}%`,
            height: "100%",
            backgroundColor: "primary.main",
            transition: "width 0.3s ease-in-out",
          }}
        />
      </Box>
    </Box>
  );
}
