export const is_number_with_special_char = (_input) => {
  /* allows only special chars other than defined below  */
  const phone_number_reg = new RegExp(/^[0-9)+(-]*$/);
  const result = phone_number_reg.test(_input);
  return result;
};

export const wrap_image_with_anchor_and_open_in_new_tab = (htmlContent) => {
  // Regex to match all <img> tags and extract the src attribute
  const imgRegex = /<img\s+([^>]*src="([^"]+)")[^>]*>/g;

  // Replace each <img> tag with a wrapped <a> tag to open in a new tab
  const updatedContent = htmlContent.replace(
    imgRegex,
    (match, imgAttributes, src) => {
      // Return the wrapped image with an anchor tag
      return `<a href="${src}" target="_blank">${match}</a>`;
    }
  );

  return updatedContent;
};
