import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Pagination,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  CircularLoader,
  Label,
  NoData,
  Page,
  WorkspaceCard,
  StyledSearch,
  MuiDialog,
  SearchBar,
  Iconify,
  MuiAutoComplete,
  MuiDrawer,
  MuiMultiAutocomplete,
  MuiSelect,
} from "src/components";
import { styled, alpha } from "@mui/material/styles";
import {
  _cancel_workspace_invitation,
  _delete_user_workspace,
  _getAllProjectsInWorkspace,
  _leave_workspace,
  _remove_user_from_workspace,
  _resend_workspace_invitation,
  _update_workspace_member,
  _user_workspaces,
} from "src/DAL";
import {
  get_from_localStorage,
  handle_localSearch,
  set_to_localStorage,
} from "src/utils";
import { filter, get } from "lodash";
import { ButtonSelect } from "src/components";
import { useAppContext } from "src/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddNewCard from "src/components/AddNewCard";
import UserCard from "../workspace-detail/components/UserCard";
import { sentenceCase } from "change-case";
import { LoadingButton } from "@mui/lab";
import InviteMembersDialog from "src/components/InviteMembersDialog";
import InvitationCard from "../workspace-detail/components/InvitationCard";

// ------------------------------------
const common_button_size = "9rem";
const FILTER_OPTIONS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Shared",
    value: "shared",
  },
  {
    label: "Mine",
    value: "mine",
  },
];
const MENU_OPTIONS = [
  {
    label: "Edit",
    roles: ["admin", "manager"],
    action: "edit",
  },
  {
    label: "Delete",
    roles: ["admin", "manager"],
    action: "delete",
  },
  {
    label: "Add Team",
    roles: ["admin", "manager", "client"],
    action: "add_team",
  },
  {
    label: "Leave",
    roles: ["team", "manager", "client", "client_team"],
    action: "leave",
  },
];
const getModified = (data) => {
  let modified = data.map((item) => {
    return {
      ...item,
      ["query_field"]: item.first_name + " " + item.last_name,
      // ["label"]: item.first_name + " " + item.last_name,
      // ["value"]: item.user_id,
    };
  });
  console.log(modified, "modified");
  return modified;
};
const WorkSpaces = () => {
  const matches_breakpoint = useMediaQuery("(max-width:858px)");
  let { state } = useLocation();
  const [workspaces, setWorkspaces] = useState(
    // get_from_localStorage("workspaces")
    //   ? get_from_localStorage("workspaces")
    //   :
    {
      invitations: [],
      personal: [],
      shared: [],
      invitationCount: 0,
      all_workspaces: [],
    }
  );
  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(
  //   get_from_localStorage("workspaces") ? false : true
  // );
  const [isLoading, setIsLoading] = useState(true);
  const [leaveReason, setLeaveReason] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);
  const [removeUserType, setRemoveUserType] = useState("remove");
  const [autoCompleteValue, setAutoCompleteValue] = useState();
  const [teamSearch, setTeamSearch] = useState("");
  const [allowedMembersForClient, setAllowedMembersForClient] = useState([]);
  const [currentWorkspace, setCurrentWorkspace] = useState();
  const [teamOpen, setTeamOpen] = useState(false);
  const [delUserDialogOpen, setDelUserDialogOpen] = useState(false);
  const [memberSettingDrawerOpen, setMemberSettingDrawerOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [removeWorkspaceUserType, setRemoveWorkspaceUserType] =
    useState("remove");
  const [memberSettingButtonLoading, setMemberSettingButtonLoading] =
    useState(false);
  const [selectedAllowedMembers, setSelectedAllowedMembers] = useState([]);
  const [memberSettings, setMemberSettings] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch_get_search_value,
    dispatch_get_current_selected_item,
    dispatch_set_current_selected_item,
    dispatch_get_user_profile,
    dispatch_set_plan_limits,
    dispatch_get_plan_limits,
  } = useAppContext();
  // ------------------------------------
  const [allProjectsList, setAllProjectsList] = useState([]);
  console.log("allProjectsList_", allProjectsList);
  //////=====Invitation new flow===========///
  const [invitationDialogOpen, setInvitationDialogOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("current");
  const [currentTargetItem, setCurrentTargetItem] = useState();
  const [cancelInvOpen, setCancelInvOpen] = useState(false);
  const [resendInvOpen, setResendInvOpen] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const openCancelInvDialog = () => {
    setCancelInvOpen(true);
  };
  const closeCancelInvDialog = () => {
    setCancelInvOpen(false);
    setCurrentTargetItem();
  };
  const openResendInvDialog = () => {
    setResendInvOpen(true);
    setCurrentTargetItem();
  };
  const closeResendInvDialog = () => {
    setResendInvOpen(false);
  };
  const openInvitationDialog = () => {
    setInvitationDialogOpen(true);
  };
  const closeInvitationDialog = () => {
    setInvitationDialogOpen(false);
    if (!teamOpen) {
      setCurrentWorkspace();
    }
  };
  const handleResendInvitation = async () => {
    try {
      setResendLoading(true);
      const result = await _resend_workspace_invitation(currentTargetItem._id);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        closeResendInvDialog();
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while resending invitation");
    } finally {
      setResendLoading(false);
    }
  };
  const handleCancelWorkspaceInvite = async () => {
    const id = currentTargetItem._id;
    setProcessing(true);
    const result = await _cancel_workspace_invitation(null, id);
    if (result.code === 200) {
      setCurrentWorkspace((prev) => {
        return {
          ...prev,
          invitations: prev.invitations.filter(
            (inv) => inv._id !== currentTargetItem._id
          ),
        };
      });
      enqueueSnackbar("Invitation cancelled successfully", {
        variant: "success",
      });
      getWorkSpaces();
      setProcessing(false);
      closeCancelInvDialog();

      // closeInvitationDrawer();
      /* filter current row if no workspaces & invitations left  for better ux, */
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
      closeCancelInvDialog();
      // closeInvitationDrawer();
    }
  };

  const getAllWorkspaceProjects = async (id) => {
    const res = await _getAllProjectsInWorkspace(id);
    if (res.code === 200) {
      setAllProjectsList(res.project_list);
      console.log("Workspace_Projects", res);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const getFilteredInvitations = (invs, search) => {
    if (!search) {
      return invs;
    } else {
      return invs.filter((inv) =>
        inv?.user_data
          ? (inv.user_data.first_name + " " + inv.user_data.last_name)
              .toLowerCase()
              .includes(search.toLowerCase())
          : //    ||
            // inv.email.toLowerCase().includes(search.toLowerCase())
            inv.email.toLowerCase().includes(search.toLowerCase())
      );
    }
  };

  const getFilteredProjectNames = (item) => {
    const matchedProjects = allProjectsList.filter((project) =>
      item.project.includes(project._id)
    );
    return matchedProjects.length > 0
      ? matchedProjects.map((project) => project.title).join(", ")
      : [];
  };
  ////////////////////////////////////////////
  const handleLocalSearch = (input, array) => {
    let filtered = filter(
      array,
      (_item) => _item.name.toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  const getFilteredList = (data) => {
    if (FILTER_OPTIONS[selectedMenuIndex].value === "all") {
      let filtered = handleLocalSearch(searchValue, data.all_workspaces);
      return filtered;
    }
    if (FILTER_OPTIONS[selectedMenuIndex].value === "shared") {
      let filtered = handleLocalSearch(searchValue, data.shared);
      return filtered;
    }
    if (FILTER_OPTIONS[selectedMenuIndex].value === "mine") {
      let filtered = handleLocalSearch(searchValue, data.personal);
      return filtered;
    }
    return [];
  };
  const openTeam = () => {
    setTeamOpen(true);
  };
  const closeTeam = () => {
    setTeamOpen(false);
    setCurrentWorkspace();
    setCurrentTab("current");
    dispatch_set_current_selected_item();
  };
  const getWorkSpaces = async () => {
    const result = await _user_workspaces();
    const getPersonal = (_data) => {
      let data = _data.map((item) => {
        return { ...item, ["role"]: "admin", ["workspace_type"]: "mine" };
      });
      return data;
    };
    const getShared = (_data) => {
      let data = _data.map((item) => {
        return { ...item, ["workspace_type"]: "shared" };
      });
      return data;
    };
    if (result.code === 200) {
      console.log(result, "workspace-res");
      setIsLoading(false);
      setWorkspaces({
        all_workspaces: result.workspace.all_workspaces,
        invitations: result.workspace.invitation,
        invitationCount: result.workspace.invitation_count,
        personal: getPersonal(result.workspace.personal),
        shared: getShared(result.workspace.share),
      });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleCloseMemberSettingDrawer = () => {
    setMemberSettingDrawerOpen(false);
    setMemberSettings();
    dispatch_set_current_selected_item();
    // setAllowedMembersForClient([]);
    setSelectedAllowedMembers([]);
  };
  const canUpdateTeam = () => {
    const { role } = currentWorkspace;
    console.log(role, "cur-user-role");
    if (
      String(role) === "team" ||
      String(role) === "client" ||
      String(role) === "client_team"
    ) {
      return false;
    }
    if (String(role) === "admin") {
      return true;
    }
    if (String(role) === "manager") {
      return true;
    }
    return false;
  };
  const getFilteredTeam = (data) => {
    if (!currentWorkspace) {
      return [];
    } else if (!teamSearch) {
      return currentWorkspace.team;
    } else {
      return currentWorkspace.team.filter((user) =>
        (
          user.first_name.toLowerCase() +
          " " +
          user.last_name.toLowerCase()
        ).includes(teamSearch.toLocaleLowerCase())
      );
    }
  };
  const getFilteredOptions = (_options, _item) => {
    let filtered = [];
    _options.map((option) => {
      option.roles.map((role) => {
        if (role === _item.role) {
          filtered.push(option);
        }
      });
    });
    return filtered;
  };
  const handleWorkspaceMenuItemClick = (_option, _index) => {
    const { action } = _option;
    if (action === "leave") {
      setLeaveDialogOpen(true);
    }
    if (action === "delete") {
      handleDeleteWorkspace(_option);
    }
    if (action === "edit") {
      handleEditWorkspace(_option);
    }
    if (action === "add_team") {
      handleAddTeamInWorkspace();
    }
  };

  const handleFilterOptionClick = (_data, _index) => {
    setSelectedMenuIndex(_index);
    getWorkSpaces();
  };
  const handleDelWorkspace = async () => {
    const item = dispatch_get_current_selected_item();
    console.log(item, "at-del");

    const getFiltered = (_data) => {
      return _data.filter((_item) => String(_item._id) !== String(item._id));
    };

    setLoading(true);
    const result = await _delete_user_workspace(null, item._id);
    if (result.code === 200) {
      console.log(result, "del-res");
      setLoading(false);
      setDialogOpen(false);
      if (item.workspace_type === "mine" || item.role === "admin") {
        console.log(item.workspace_type, "type");
        const new_list = {
          ...workspaces,
          ["personal"]: getFiltered(workspaces.personal),
        };
        setWorkspaces(new_list);
        // set_to_localStorage("workspaces", new_list);
      }
      if (item.workspace_type === "shared") {
        console.log(item.workspace_type, "type");
        setWorkspaces({
          ...workspaces,
          ["shared"]: getFiltered(workspaces.shared),
        });
      }

      enqueueSnackbar("Workspace deleted successfully", { variant: "success" });
      getWorkSpaces();
      dispatch_set_plan_limits({
        ...dispatch_get_plan_limits(),
        workspace_count: dispatch_get_plan_limits().workspace_count - 1,
      });
    } else {
      setLoading(false);
      setDialogOpen(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleLeaveWorkspace = async (e) => {
    e.preventDefault();

    const item = dispatch_get_current_selected_item();
    console.log(item, "at-leave");
    console.log(leaveReason, "at-leave");
    if (!leaveReason) {
      enqueueSnackbar("Please write a reason to leave", { variant: "error" });
      return;
    }
    if (leaveReason.length < 10) {
      enqueueSnackbar("Reason should be at least 10 characters long", {
        variant: "error",
      });
      return;
    }
    if (removeUserType == "assign" && !autoCompleteValue) {
      enqueueSnackbar("Please select the user to assign tasks", {
        variant: "error",
      });
      return;
    }
    const data = {
      workspace_id: item._id,
      message: leaveReason,
      member_id: autoCompleteValue?.value,
    };

    setLoading(true);
    const result = await _leave_workspace(data);
    if (result.code === 200) {
      console.log(result, "leave-res");
      const getFiltered = (_data) => {
        return _data.filter((_item) => String(_item._id) !== String(item._id));
      };
      setLoading(false);
      if (item.workspace_type === "mine") {
        console.log(item.workspace_type, "type");
        setWorkspaces({
          ...workspaces,
          ["personal"]: getFiltered(workspaces.personal),
        });
      }
      if (item.workspace_type === "shared") {
        console.log(item.workspace_type, "type");
        const new_list = {
          ...workspaces,
          ["shared"]: getFiltered(workspaces.shared),
        };
        setWorkspaces(new_list);
        // set_to_localStorage("workspaces", new_list);
      }
      enqueueSnackbar(`Left Workspace Successfully`, { variant: "success" });
      getWorkSpaces();
      setLeaveDialogOpen(false);
      setLeaveReason("");
    } else {
      setLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleDeleteWorkspace = (_data) => {
    setDialogOpen(true);
  };
  const handleEditWorkspace = (_data) => {
    const item = dispatch_get_current_selected_item();
    console.log(_data, item, "at-edit");
    navigate(`/workspaces/edit/${item._id}`, {
      state: { item: item },
    });
  };
  const handleAddTeamInWorkspace = (_data) => {
    // const item = dispatch_get_current_selected_item();
    // navigate(`/team/add-team`, { state: { workspace: item } });
    openInvitationDialog();
  };
  /* persistence  */
  const getPersistedWorkSpaceType = () => {
    let index = get_from_localStorage("workspace-selected-index");
    if (index) {
      setSelectedMenuIndex(Number(index));
    }
  };
  //============================================================================
  const openDelUserDialog = () => {
    setDelUserDialogOpen(true);
  };
  const onClickUserSettings = () => {
    setMemberSettingDrawerOpen(true);
  };
  const handleChangeMemberSettings = (e) => {
    setMemberSettings((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const closeDelUserDialog = () => {
    setDelUserDialogOpen(false);
    setRemoveWorkspaceUserType("remove");
    // setAutoCompleteValue();
  };
  const handleRemoveUser = async () => {
    const selected_item = dispatch_get_current_selected_item();
    if (!selected_item) {
      return;
    }
    if (removeWorkspaceUserType == "assign" && !autoCompleteValue) {
      enqueueSnackbar("Please select the user to assign the tasks", {
        variant: "error",
      });
      return;
    }
    const data = {
      workspace: [currentWorkspace._id],
      user_id: selected_item.user_id,
      member_id: autoCompleteValue?.value,
    };
    setProcessing(true);
    const result = await _remove_user_from_workspace(data);
    if (result.code === 200) {
      console.log(result, "del-user-res");
      setProcessing(false);
      enqueueSnackbar("User Removed Successfully", { variant: "success" });
      const getFiltered = (data) => {
        let filtered = data.filter((item) => {
          if (String(item._id) !== String(selected_item._id)) {
            return item;
          }
        });
        return filtered;
      };
      // setSlugTeamData({
      //   ...slugTeamData,
      //   profiles: getFiltered(slugTeamData.profiles),
      //   totalMembers: Number(slugTeamData.totalMembers) - 1,
      // });
      setCurrentWorkspace((prev) => {
        return { ...prev, team: getFiltered(prev.team) };
      });
      console.log(selected_item, "to-remove");
      // let temp = slugProjectsData.projects;
      // let tempProjects = temp.map((project) => {
      //   return {
      //     ...project,
      //     team: getFiltered(project.team),
      //   };
      // });
      // setSlugProjectsData((prev) => {
      //   return {
      //     ...prev,
      //     projects: tempProjects,
      //   };
      // });
      // if (currentWorkspace.role == "admin") {
      //   setWorkspaces((prev) => {
      //     return {
      //       ...prev,
      //       personal: prev.personal.map((workspace) => {
      //         if (workspace._id == currentWorkspace._id) {
      //           return {
      //             ...workspace,
      //             member: workspace.member - 1,
      //             team: workspace.team.filter(
      //               (member) => member.user_id !== selected_item.user_id
      //             ),
      //           };
      //         }
      //       }),
      //     };
      //   });
      // } else {
      //   setWorkspaces((prev) => {
      //     return {
      //       ...prev,
      //       shared: prev.shared.map((workspace) => {
      //         if (workspace._id == currentWorkspace._id) {
      //           return {
      //             ...workspace,
      //             member: workspace.member - 1,
      //             team: workspace.team.filter(
      //               (member) => member.user_id !== selected_item.user_id
      //             ),
      //           };
      //         }
      //       }),
      //     };
      //   });
      // }
      getWorkSpaces();

      closeDelUserDialog();
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleUpdateMember = async () => {
    try {
      setMemberSettingButtonLoading(true);
      const req_obj = {
        allow_drag_drop:
          memberSettings.allow_drag_drop == "true" ? true : false,
        allow_add_project:
          memberSettings.allow_add_project == "true" ? true : false,
        role: memberSettings.role,
        workspace_id: currentWorkspace._id,
        allow_users:
          memberSettings.role == "client" ||
          memberSettings.role == "client_team"
            ? selectedAllowedMembers.map((member) => member.user_id)
            : [],
      };
      const result = await _update_workspace_member(
        req_obj,
        memberSettings.user_id
      );
      if (result.code == 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        // setMemberSettingDrawerOpen(false);
        delete req_obj.workspace_id;
        setCurrentWorkspace((prev) => {
          return {
            ...prev,
            team: prev.team.map((member) => {
              if (member.user_id == memberSettings.user_id) {
                return {
                  ...member,
                  ...req_obj,
                };
              } else {
                return { ...member };
              }
            }),
          };
        });
        handleCloseMemberSettingDrawer();
        if (currentWorkspace.role == "admin") {
          setWorkspaces((prev) => {
            return {
              ...prev,
              personal: prev.personal.map((workspace) => {
                if (workspace._id == currentWorkspace._id) {
                  return {
                    ...workspace,
                    team: workspace.team.map((member) => {
                      if (member.user_id == memberSettings.user_id) {
                        return {
                          ...member,
                          ...req_obj,
                        };
                      } else {
                        return { ...member };
                      }
                    }),
                  };
                } else {
                  return { ...workspace };
                }
              }),
            };
          });
        } else {
          setWorkspaces((prev) => {
            return {
              ...prev,
              shared: prev.shared.map((workspace) => {
                if (workspace._id == currentWorkspace._id) {
                  return {
                    ...workspace,
                    team: workspace.team.map((member) => {
                      if (member.user_id == memberSettings.user_id) {
                        return {
                          ...member,
                          ...req_obj,
                        };
                      } else {
                        return { ...member };
                      }
                    }),
                  };
                } else {
                  return { ...workspace };
                }
              }),
            };
          });
        }
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while updating the member");
    } finally {
      setMemberSettingButtonLoading(false);
    }
  };
  //=====================================
  useEffect(() => {
    if (currentWorkspace) {
      getAllWorkspaceProjects(currentWorkspace._id);
    }
  }, [currentWorkspace]);
  useEffect(() => {
    if (memberSettingDrawerOpen) {
      allowedMembersForClient.map((member) => {
        let index = dispatch_get_current_selected_item().allow_users.indexOf(
          member.user_id
        );
        if (index !== -1) {
          setSelectedAllowedMembers((prev) => [...prev, member]);
        }
      });
    }
  }, [memberSettingDrawerOpen]);
  useEffect(() => {
    if (currentWorkspace) {
      let tempTeam = [
        {
          ...currentWorkspace.admin,
          label:
            currentWorkspace.admin.first_name +
            " " +
            currentWorkspace.admin.last_name +
            " (admin)",
          value: { ...currentWorkspace.admin },
        },
        ...getModified(currentWorkspace.team).map((member) => {
          return {
            ...member,
            label:
              member.first_name +
              " " +
              member.last_name +
              " (" +
              member.role +
              ")",
            value: { ...member },
          };
        }),
      ];

      const current_user = dispatch_get_user_profile();

      if (currentWorkspace.user_id !== current_user.user_id) {
        tempTeam = tempTeam.filter(
          (member) => member.role !== "client" && member.role !== "client_team"
        );
      }
      setAllowedMembersForClient(tempTeam);
    }

    console.log(currentWorkspace, "--currentWorkspace");
    console.log(dispatch_get_user_profile(), "--currentWorkspace");
  }, [currentWorkspace]);
  // ------------------------------------
  useLayoutEffect(() => {
    /*fetch data here */
    // !get_from_localStorage("workspaces") &&
    getWorkSpaces();
  }, []);
  useLayoutEffect(() => {
    /*get persisted data here */
    getPersistedWorkSpaceType();
  }, []);
  useLayoutEffect(() => {
    /*persist data here */
    set_to_localStorage("workspace-selected-index", selectedMenuIndex);
  });
  // useEffect(() => {
  //   set_to_localStorage("workspaces", workspaces);
  // }, [workspaces]);
  const getTeamMembers = () => {
    const members = dispatch_get_current_selected_item().team.map((member) => {
      return {
        label: member.first_name + " " + member.last_name,
        value: member.user_id,
      };
    });
    // members.push({
    //   label: dispatch_get_current_selected_item().user.name,
    //   value: dispatch_get_current_selected_item().user_id,
    // });
    return members.filter(
      (member) => member.value !== dispatch_get_user_profile().user_id
    );
  };
  const getTeamMembersForRemoveUser = () => {
    const members = currentWorkspace.team.map((member) => {
      return {
        label: member.first_name + " " + member.last_name,
        value: member.user_id,
      };
    });
    members.push({
      label:
        currentWorkspace?.admin.first_name +
        " " +
        currentWorkspace?.admin.last_name,
      value: currentWorkspace?.admin.user_id,
    });
    return members.filter(
      (member) => member.value !== dispatch_get_current_selected_item().user_id
    );
  };
  return (
    <Page title="Workspaces">
      <Container maxWidth="xl">
        <div className="mb-4">
          <Grid
            rowSpacing={matches_breakpoint ? 2 : 0}
            alignItems="center"
            container
            columnSpacing={2}
          >
            <Grid className="d-flex" item>
              <Typography
                sx={{ fontSize: { xs: "27px", sm: "29px", md: "29px" } }}
                variant="h4"
              >
                Workspaces
              </Typography>
              <ButtonSelect
                onFilterOptionClick={handleFilterOptionClick}
                options={FILTER_OPTIONS}
                selectedIndex={selectedMenuIndex}
                sx={{ marginLeft: 2 }}
                title="Type"
              />
            </Grid>
            <Grid
              item
              sx={{
                marginLeft: { xs: "initial", sm: "auto" },
              }}
            >
              {/* <Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                color='primary'
                badgeContent={workspaces.invitationCount}
                max={99}
                showZero
                sx={{
                  '& .MuiBadge-badge': {
                    color: 'white',
                    backgroundColor: (theme) => theme.palette.info.main,
                  },
                }}
              >
                <Button
                  variant='contained'
                  sx={{ width: common_button_size }}
                  onClick={() =>
                    navigate('/workspaces/invitations', {
                      state: { workspace_invitations: workspaces.invitations },
                    })
                  }
                  // endIcon={<Iconify icon='fluent:mail-unread-48-regular' />}
                >
                  Invitations
                </Button>
              </Badge> */}
              <Button
                variant="contained"
                // sx={{ marginLeft: 2,  }}
                onClick={() => navigate("/workspaces/create")}
                // endIcon={<Iconify icon='akar-icons:plus' />}
              >
                New Workspace
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className="mb-4">
          <Stack
            direction="row"
            sx={{ justifyContent: { xs: "flex-start", sm: "flex-end" } }}
          >
            <SearchBar
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Stack>
        </div>
        <Grid container spacing={3}>
          {getFilteredList(workspaces).map((item, index) => {
            return (
              <Grid
                sx={{ position: "relative" }}
                key={index}
                item
                xs={12}
                sm={6}
                md={3}
                xl={2}
              >
                <WorkspaceCard
                  onClickMenuItem={(option, index) => {
                    setCurrentWorkspace(item);
                    handleWorkspaceMenuItemClick(option, index);
                  }}
                  onDeleteButtonClick={() => {
                    dispatch_set_current_selected_item(item);
                    handleDeleteWorkspace();
                  }}
                  menuOptions={getFilteredOptions(MENU_OPTIONS, item)}
                  item={item}
                  onClickTeam={(item) => {
                    setCurrentWorkspace(item);
                    openTeam();
                  }}
                />
              </Grid>
            );
          })}
          {((workspaces.personal.length == 0 &&
            workspaces.shared.length == 0) ||
            (workspaces.personal.length == 0 && selectedMenuIndex == 2)) &&
            !isLoading && (
              <AddNewCard
                title="workspace"
                handleClick={() => {
                  navigate("/workspaces/create");
                }}
              />
            )}
          {/* <NoData
            dataLength={getFilteredList(workspaces).length}
            loading={isLoading}
            title="No Workspaces Found !"
          /> */}
        </Grid>
        {/* <div
          className="d-flex justify-content-center"
          style={{ marginTop: "30px" }}
        >
          <Pagination count={10} color="primary" />
        </div> */}
        {isLoading && <CircularLoader />}
      </Container>
      {currentWorkspace && (
        <MuiDrawer
          onClose={closeTeam}
          isOpen={teamOpen}
          title="Workspace Team"
          titleButton={
            currentWorkspace?.role !== "team" &&
            currentWorkspace?.role !== "client_team" && (
              <Button
                variant="contained"
                size="small"
                onClick={openInvitationDialog}
              >
                Invite members
              </Button>
            )
          }
        >
          <Tabs
            sx={{ pt: 0 }}
            value={currentTab}
            onChange={(e, val) => {
              setTeamSearch("");
              setCurrentTab(val);
            }}
          >
            <Tab
              sx={{ pt: 0, width: "50%" }}
              value={"current"}
              label="Current Team"
            />
            <Tab
              value="invited"
              label="Invited Team"
              sx={{ width: "50%", pt: 0 }}
            />
          </Tabs>
          <Paper sx={{ mb: 2 }} elevation={0} className="search-bar">
            <SearchBar
              notransition="true"
              sx={{ width: "100%" }}
              value={teamSearch}
              onChange={(e) => setTeamSearch(e.target.value)}
            />
          </Paper>
          {currentTab == "current" && (
            <>
              <Stack spacing={2}>
                {getFilteredTeam(currentWorkspace.team).map((item, index) => (
                  <>
                    <UserCard
                      onRemoveUser={openDelUserDialog}
                      onSettings={onClickUserSettings}
                      setMemberSettings={setMemberSettings}
                      userRole={currentWorkspace?.role}
                      key={index}
                      item={item}
                      allowedMembersForClient={allowedMembersForClient}
                    />
                  </>
                ))}
              </Stack>
              {getFilteredTeam(currentWorkspace.team).length === 0 && (
                <Typography
                  color={(theme) => theme.palette.text.disabled}
                  sx={{ mt: 20 }}
                  textAlign="center"
                >
                  No team members found
                </Typography>
              )}
            </>
          )}
          {currentTab == "invited" && (
            <>
              <Stack spacing={2}>
                {getFilteredInvitations(
                  currentWorkspace.invitations,
                  teamSearch
                ).map((item) => (
                  <InvitationCard
                    onCancel={(data) => {
                      setCurrentTargetItem(data);
                      openCancelInvDialog();
                    }}
                    onResend={(data) => {
                      setCurrentTargetItem(data);
                      openResendInvDialog();
                    }}
                    key={item._id}
                    item={item}
                    projectName={getFilteredProjectNames(item)}
                  />
                ))}
              </Stack>

              {getFilteredInvitations(currentWorkspace.invitations, teamSearch)
                .length === 0 && (
                <Typography
                  color={(theme) => theme.palette.text.disabled}
                  sx={{ mt: 20 }}
                  textAlign="center"
                >
                  No invitations found!
                </Typography>
              )}
            </>
          )}
        </MuiDrawer>
      )}
      {memberSettings && (
        <MuiDrawer
          title={
            sentenceCase(
              memberSettings.first_name + " " + memberSettings.last_name
            ) + " settings"
          }
          isOpen={memberSettingDrawerOpen}
          onClose={handleCloseMemberSettingDrawer}
          bottomComponent={
            canUpdateTeam() && (
              <>
                <div className="centered-row project-team-update-btn">
                  <LoadingButton
                    id="update-team-button"
                    // className="d-none"
                    variant="contained"
                    onClick={handleUpdateMember}
                    loading={memberSettingButtonLoading}
                  >
                    Update
                  </LoadingButton>
                </div>
              </>
            )
          }
        >
          <Stack spacing={3}>
            {(dispatch_get_current_selected_item().role == "team" ||
              dispatch_get_current_selected_item().role == "manager") && (
              <MuiSelect
                value={memberSettings.role}
                name="role"
                onChange={handleChangeMemberSettings}
                options={[
                  {
                    label: "Manager",
                    value: "manager",
                  },
                  {
                    label: "Team",
                    value: "team",
                  },
                ]}
                label="Choose Role"
              />
            )}
            {(dispatch_get_current_selected_item().role == "client" ||
              dispatch_get_current_selected_item().role == "client_team") && (
              <MuiMultiAutocomplete
                value={selectedAllowedMembers}
                options={allowedMembersForClient}
                onChange={(val) => setSelectedAllowedMembers(val)}
                label="Choose Members"
                fixedOptions={allowedMembersForClient.filter(
                  (member) =>
                    member.role == "admin" ||
                    member.user_id == dispatch_get_user_profile().user_id
                )}
              />
            )}
            {memberSettings.role == "team" && (
              <>
                <FormControl>
                  <FormLabel>Task Drag-Drop</FormLabel>
                  <RadioGroup
                    title="Drag-Drop"
                    row
                    disabled={memberSettingButtonLoading}
                    aria-labelledby="gender-select-radio-buttons-label"
                    name="allow_drag_drop"
                    value={memberSettings.allow_drag_drop}
                    onChange={handleChangeMemberSettings}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Allow"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Disallow"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>Add project</FormLabel>
                  <RadioGroup
                    title="Drag-Drop"
                    row
                    disabled={memberSettingButtonLoading}
                    aria-labelledby="gender-select-radio-buttons-label"
                    name="allow_add_project"
                    value={memberSettings.allow_add_project}
                    onChange={handleChangeMemberSettings}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Allow"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Disallow"
                    />
                  </RadioGroup>
                </FormControl>
              </>
            )}
          </Stack>
        </MuiDrawer>
      )}
      {/* dialogs */}
      <MuiDialog
        title="Delete Workspace"
        message={`Are you sure you want to delete the workspace "${
          dispatch_get_current_selected_item()?.name
        }" ?`}
        open={dialogOpen}
        onToggle={(value) => setDialogOpen(value)}
        onAgree={handleDelWorkspace}
        loading={loading}
      />
      {/*  */}
      <MuiDialog
        title="Leave Workspace"
        message={`Are you sure you want to leave the workspace "${
          dispatch_get_current_selected_item()?.name
        }" ?`}
        open={leaveDialogOpen}
        onToggle={(value) => {
          setLeaveReason("");
          setLeaveDialogOpen(value);
        }}
        onAgree={handleLeaveWorkspace}
        loading={loading}
        ExtraComponent={
          <>
            <TextField
              value={leaveReason}
              onChange={(e) => setLeaveReason(e.target.value)}
              fullWidth
              required
              variant="outlined"
              label="Reason"
            />
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={removeUserType}
              onChange={(e) => setRemoveUserType(e.target.value)}
            >
              <FormControlLabel
                value="remove"
                control={<Radio />}
                label="Delete all tasks created by you and remove yourself as task member"
              />
              <FormControlLabel
                value="assign"
                control={<Radio />}
                label="Assign the tasks, you created, to some other team member"
              />
            </RadioGroup>
            {removeUserType === "assign" && (
              <MuiAutoComplete
                onChange={(val) => setAutoCompleteValue(val)}
                value={autoCompleteValue}
                label="Team Members"
                options={getTeamMembers()}
              />
            )}
          </>
        }
      />
      <Dialog
        open={delUserDialogOpen}
        onClose={closeDelUserDialog}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Remove User</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}
          >
            Are you sure you want to remove this user?
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={removeWorkspaceUserType}
              onChange={(e) => setRemoveWorkspaceUserType(e.target.value)}
            >
              <FormControlLabel
                value="remove"
                control={<Radio />}
                label="Delete all tasks created by this user and remove as task member"
              />
              <FormControlLabel
                value="assign"
                control={<Radio />}
                label="Assign the tasks created by this user to some other team member"
              />
            </RadioGroup>
            {removeWorkspaceUserType === "assign" && (
              <MuiAutoComplete
                onChange={(val) => setAutoCompleteValue(val)}
                value={autoCompleteValue}
                label="Team Members"
                options={getTeamMembersForRemoveUser()}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDelUserDialog}>Cancel</Button>
          <Button onClick={handleRemoveUser}>Yes</Button>
        </DialogActions>
      </Dialog>
      {currentWorkspace && (
        <InviteMembersDialog
          open={invitationDialogOpen}
          onClose={closeInvitationDialog}
          workspace={currentWorkspace}
          onSuccess={(inv) => {
            getWorkSpaces();
            if (teamOpen) {
              setCurrentWorkspace((prev) => {
                return { ...prev, invitations: [...prev.invitations, inv] };
              });
            }
          }}
        />
      )}
      <MuiDialog
        open={cancelInvOpen}
        message="Are you sure you want to cancel your invite?"
        title="Cancel invite"
        onToggle={(value) => setCancelInvOpen(value)}
        onAgree={handleCancelWorkspaceInvite}
        loading={processing}
      />
      <MuiDialog
        open={resendInvOpen}
        message="Are you sure you want to re-send this invitation?"
        title="Resend invite"
        onToggle={(value) => setResendInvOpen(value)}
        onAgree={handleResendInvitation}
        loading={resendLoading}
      />
    </Page>
  );
};

export default WorkSpaces;
const LeaveMessage = () => {
  return (
    <>
      <TextField fullWidth variant="outlined" label="Reason" />
    </>
  );
};
