import {
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Tooltip,
  InputLabel,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useTransition,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Iconify,
  MuiAutoComplete,
  MuiDrawer,
  MuiMultiAutocomplete,
  MuiSelect,
  NavBackHeader,
  Page,
} from "src/components";
import { debounce } from "lodash";
import {
  _getAllProjectsInWorkspace,
  _getWorkspaceTeamWithAdmin,
  _invite_user_to_workspace,
  _search_users,
  _user_workspaces,
} from "src/DAL";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { get_from_localStorage } from "src/utils";
import { useAppContext } from "src/hooks";
import {
  _user_workspace_detail,
  _workspace_admin_detail,
} from "src/DAL/workspace";
import TeamUserCard from "src/pages/add-team/components/TeamUserCard";
import DummyUserCard from "src/pages/add-team/components/DummyUserCard";
// ----------------------------------------------------
/* DEBOUNCE SEARCH */
const fetchData = async (query, cb) => {
  const res = await _search_users(undefined, query);
  cb(res);
};
const debouncedFetchData = debounce((query, cb) => {
  fetchData(query, cb);
}, 1000);
// ----------------------------------------------------
const ROLE_OPTIONS = [
  {
    label: "Manager",
    value: "manager",
  },
  {
    label: "Team",
    value: "team",
  },
  {
    label: "Client",
    value: "client",
  },
];

function InviteMembersDialog(props) {
  const isClient = Boolean(props?.client);
  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();
  const {
    dispatch_get_search_value,
    dispatch_set_search_value,
    socketEmit,
    dispatch_get_user_profile,
    setLimitReachedDialogOpen,
    dispatch_get_plan_limits,
    dispatch_set_plan_limits,
  } = useAppContext();
  const [searchValue, setSearchValue] = useState(dispatch_get_search_value());
  const [roleValue, setRoleValue] = useState(isClient ? "client" : "team");
  const [dragValue, setDragValue] = useState("1");
  const [addProjValue, setAddProjValue] = useState("1");
  const [perHourRate, setPerHourRate] = useState("");
  const [loading, setLoading] = useState(false);
  const [inviteDrawerOpen, setInviteDrawerOpen] = useState(false);
  const [sendingInv, setSendingInv] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userList, setUserList] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [membersAutoComplete, setMembersAutoComplete] = useState([]);
  const [workspaceAdminDetail, setWorkspaceAdminDetail] = useState();
  console.log(workspaceAdminDetail, "dettttailll");
  const [workspaceDataLoading, setWorkspaceDataLoading] = useState(false);
  const [stateWorkspaceDetail, setStateWorkspaceDetail] = useState();
  const [workspaceAutoComplete, setWorkspaceAutoComplete] = useState(
    props?.workspace
      ? { ...props?.workspace, label: props?.workspace?.name }
      : null
  );
  const [memberOptions, setMemberOptions] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectAutoComplete, setProjectAutoComplete] = useState(
    props?.project ? [{ ...props?.project, label: props?.project.title }] : []
  );

// ----------------------------------------------------
  const closeDialog = () => {
    setWorkspaceAutoComplete(
      props?.workspace
        ? { ...props?.workspace, label: props?.workspace?.name }
        : null
    );
    setProjectAutoComplete(
      props?.project ? [{ ...props?.project, label: props?.project.title }] : []
    );
    setMemberOptions([]);
    setMembersAutoComplete([]);
    setRoleValue("team");
    setInviteDrawerOpen(false);
    setDragValue("1");
    setAddProjValue("1");
    setSearchValue("");
    props?.onClose();
  };

  const getProjectsOfWorkspace = async (id) => {
    try {
      const result = await _getAllProjectsInWorkspace(id);
      if (result.code == 200) {
        setProjects(
          result.project_list.map((project) => {
            return {
              ...project,
              label: project.title,
              value: project,
            };
          })
        );
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " error catched while fetching projects");
    }
  };

  useEffect(() => {
    if (workspaceAutoComplete) {
      getProjectsOfWorkspace(workspaceAutoComplete._id);
    }
  }, [workspaceAutoComplete]);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleAutocompleteChange = (value, type) => {
    console.log(value, "val");
    if (type == "workspace") {
      setWorkspaceAutoComplete(value);

      setProjectAutoComplete([]);
      // getWorkspaceAllTeam(value._id);
    } else if (type == "project") {
      if (value.find((obj) => obj.value == "all")) {
        setProjectAutoComplete([...projects]);
      } else {
        setProjectAutoComplete(value);
      }
    } else if (type == "member") {
      setMembersAutoComplete(value);
    }
  };

  const getWorkspaceAdminDetail = async (id) => {
    try {
      setWorkspaceDataLoading(true);
      const result = await _workspace_admin_detail(id);
      console.log(result, "resultworkspceeeee");
      setWorkspaceAdminDetail(result);
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while fetching admin details");
    } finally {
      setWorkspaceDataLoading(false);
    }
  };
  const getWorkspaceDetail = async () => {
    const result = await _user_workspace_detail(null, props?.workspace?._id);
    if (result.code === 200) {
      setStateWorkspaceDetail(result.workspace);
      props?.setWorkspace(result.workspace);
    } else if (result.code == 400) {
      navigate("/workspaces");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    if (workspaceAutoComplete) {
      let value = { ...workspaceAutoComplete };
      setMembersAutoComplete([
        {
          ...value?.admin,
          label:
            value?.admin?.first_name +
            " " +
            value?.admin?.last_name +
            " (admin)",
          value: { ...value?.admin },
        },
        ...value.team
          .filter((member) => member.role == "manager")
          .map((member) => {
            return {
              ...member,
              label: member.first_name + " " + member.last_name + " (manager)",
              value: member,
            };
          }),
      ]);
      setMemberOptions([
        {
          ...value?.admin,
          label:
            value?.admin?.first_name +
            " " +
            value?.admin?.last_name +
            " (admin)",
          value: { ...value.admin },
        },
        ...value.team?.map((member) => {
          return {
            ...member,
            label:
              member.first_name +
              " " +
              member.last_name +
              " (" +
              member.role +
              ")",
            value: member,
          };
        }),
      ]);
      getWorkspaceAdminDetail(workspaceAutoComplete.user_id);
    }
  }, [workspaceAutoComplete]);
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleChangeDrag = (e) => {
    setDragValue(e.target.value);
  };
  const handleChangeAddProj = (e) => {
    setAddProjValue(e.target.value);
  };
  const onChangeRole = (e) => {
    setRoleValue(e.target.value);
  };
  const onInviteUser = (_data) => {
    console.log(_data);
    setSelectedUser(_data);
    setInviteDrawerOpen(true);
    getWorkSpaces();
  };
  const closeInvDrawer = (_data) => {
    setWorkspaceAutoComplete(
      props?.workspace
        ? { ...props?.workspace, label: props?.workspace?.name }
        : null
    );
    setProjectAutoComplete(
      props?.project ? [{ ...props?.project, label: props?.project.title }] : []
    );
    setMemberOptions([]);
    setMembersAutoComplete([]);
    setRoleValue("team");
    setInviteDrawerOpen(false);
    setDragValue("1");
    setAddProjValue("1");
  };
  //////////////////////////////////
  var isEmailFormat = function (string) {
    return /^[\w\.-]+@[\w\.-]+\.\w+$/.test(string);
  };

  ////////////////////////////////////////
  const getText = () => {
    const user_count = userList.length;
    let not_found = !isEmailFormat(searchValue)
      ? `No Results found for "${searchValue}"`
      : "";
    if (searchValue === "") {
      if (!loading) {
        if (userList.length === 0) {
          return "Start Searching Users";
        }
        if (userList.length > 0) {
          return `Found ${user_count} ${user_count === 1 ? "User" : "Users"}`;
        }
      }
      if (loading) {
        return "Searching Users...";
      }
    }
    if (searchValue !== "") {
      if (!loading) {
        if (userList.length === 0) {
          return not_found;
        }
        if (userList.length > 0) {
          return `Found ${user_count} ${user_count === 1 ? "User" : "Users"}`;
        }
      }
      if (loading) {
        return "Searching Users...";
      }
    }
  };
  const onRoleOptionClick = (e, _option) => {
    console.log(_option);
    // setRoleValue(e.target.value);
  };
  const getWorkSpaces = async () => {
    const result = await _user_workspaces();
    const getPersonal = (_data) => {
      let data = _data.map((item) => {
        return {
          ...item,
          ["role"]: item.role,
          ["workspace_type"]: "mine",
          ["label"]: item.name,
          ["value"]: item,
        };
      });
      return data;
    };
    if (result.code === 200) {
      console.log(result, "workspace-res");
      const with_manager_role = result.workspace?.share
        .filter(
          (item) =>
            String(item.role) === "manager" || String(item.role) === "client"
        )
        .map((_item) => {
          return { ..._item, ["label"]: _item.name, ["value"]: _item };
        });
      if (isClient) {
        setWorkspaces(
          [
            ...getPersonal(result.workspace?.personal),
            ...with_manager_role,
          ].filter((item) => item.role !== "client")
        );
      } else {
        setWorkspaces([
          ...getPersonal(result.workspace?.personal),
          ...with_manager_role,
        ]);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSendInvite = async () => {
    if (!workspaceAutoComplete) {
      enqueueSnackbar("Please Choose a Workspace", { variant: "error" });
      return;
    }
    if (!roleValue) {
      enqueueSnackbar("Please Choose a Role", { variant: "error" });
      return;
    }
    if (!selectedUser) {
      enqueueSnackbar("No user selected", { variant: "error" });
      return;
    }
    if (roleValue == "team" || roleValue == "manager") {
      if (
        workspaceAdminDetail?.count_data?.team_manager_count >=
        workspaceAdminDetail?.current_plans?.team_member_allow
      ) {
        if (
          workspaceAdminDetail.customer.user_id ==
          dispatch_get_user_profile().user_id
        ) {
          setLimitReachedDialogOpen(true);
        } else {
          enqueueSnackbar(
            "Team limit for the admin of this workspace reached.",
            {
              variant: "error",
            }
          );
        }
        return;
      }
    } else {
      if (
        workspaceAdminDetail?.count_data?.client_count >=
        workspaceAdminDetail?.current_plans?.client_allow
      ) {
        if (
          workspaceAdminDetail.customer.user_id ==
          dispatch_get_user_profile().user_id
        ) {
          setLimitReachedDialogOpen(true);
        } else {
          enqueueSnackbar(
            "Client limit for the admin of this workspace reached.",
            {
              variant: "error",
            }
          );
        }
        return;
      }
    }

    console.log(workspaceAutoComplete, "workspace");
    console.log(roleValue, "role");
    console.log(selectedUser, "selected-user");
    let drag_drop = true;
    let add_project = true;
    if (roleValue === "team" || roleValue === "client") {
      if (dragValue === "0") {
        drag_drop = false;
      }
    }
    if (roleValue === "team" || roleValue === "client") {
      if (addProjValue === "0") {
        add_project = false;
      }
    }
    const data = {
      project_id: projectAutoComplete.map((project) => {
        return project._id;
      }),
      allow_users:
        workspaceAutoComplete.role == "client"
          ? [
              ...workspaceAutoComplete.current_customer.allow_users,
              workspaceAutoComplete.current_customer.user_id,
            ]
          : membersAutoComplete.map((member) => {
              return member.user_id;
            }),
      workspace: workspaceAutoComplete._id,
      email: selectedUser.email,
      role: workspaceAutoComplete.role == "client" ? "client_team" : roleValue,
      token: "",
      allow_drag_drop:
        workspaceAutoComplete.role == "client"
          ? workspaceAutoComplete.allow_drag_drop
          : drag_drop,
      allow_add_project:
        workspaceAutoComplete.role == "client"
          ? workspaceAutoComplete.allow_add_project
          : add_project,
      price: workspaceAutoComplete.role == "client" ? "" : perHourRate,
    };
    console.log(data, "inv-req");
    setSendingInv(true);
    const result = await _invite_user_to_workspace(data);
    if (result.code === 200) {
      console.log(result, "inv-res");
      setSendingInv(false);
      const receiver = [selectedUser.user_id];
      socketEmit("send_notification_to_all", {
        receiver,
        data: result.invitation,
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } invites you to join ${
          workspaceAutoComplete.name
        } workspace as ${roleValue}`,
        link: `${window.location.origin}/invitations`,
      });
      enqueueSnackbar("Invitation Sent Successfully", { variant: "success" });
      if (props?.workspace) {
        setUserList((prev) =>
          prev.map((user) => {
            if (user.user_id === selectedUser.user_id) {
              return { ...user, is_invited: true };
            } else {
              return user;
            }
          })
        );
      }
      closeInvDrawer();
      props?.onSuccess(result.invitation);
    } else {
      setSendingInv(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  // ----------------------------------------------------

  useLayoutEffect(() => {
    getWorkSpaces();
  }, []);
  useLayoutEffect(() => {
    if (props?.workspace) {
      getWorkspaceDetail();
    }
  }, []);

  React.useEffect(() => {
    if (searchValue === "") {
      setUserList([]);
      setLoading(false);
    }
    if (searchValue !== "") {
      setLoading(true);
      debouncedFetchData(searchValue, (res) => {
        let modified = res.data.customer.map((item) => {
          return {
            ...item,
            is_invited: Boolean(
              stateWorkspaceDetail?.invitations?.find(
                (inv) => inv.email == item.email
              )
            ),
            is_added: Boolean(
              stateWorkspaceDetail?.team?.find((inv) => inv.email == item.email)
            ),
          };
        });
        setUserList(modified);
        console.log(modified, "res");
        setLoading(false);
      });
    }
    return () => { 
      debouncedFetchData.cancel(); //cancel debounce when searchValue changes before timeout
    };
  }, [searchValue]);
  
  React.useEffect(() => {
    if (searchValue === "") {
      if (userList.length !== 0) {
        setUserList([]);
      }
    }
  });

  return (
    <Dialog open={props?.open} onClose={closeDialog} maxWidth="lg" fullWidth>
      <DialogTitle className="d-flex justify-content-between align-items-center ">
        {!isClient ? "Invite Team member" : "Invite Client"}
        <IconButton onClick={closeDialog}>
          <Iconify icon="maki:cross" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ height: "90vh" }}>
        {!inviteDrawerOpen && (
          <Stack spacing={2.5} pt={1}>
            <Grid alignItems="center" columnSpacing={1} container>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Search user"
                  value={searchValue}
                  onChange={handleChange}
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position='end'>
                  //       <IconButton edge='end'>
                  //         <CircularProgress
                  //           sx={{
                  //             display: `${loading ? 'block' : 'none'}`,
                  //           }}
                  //           size={'1.5rem'}
                  //         />
                  //       </IconButton>
                  //     </InputAdornment>
                  //   ),
                  // }}
                />
              </Grid>
            </Grid>
            <div style={{ height: "1.5rem" }}>
              {getText() === "Searching Users..." ? (
                <div className="centered-row">
                  <CircularProgress
                    sx={{
                      display: `${loading ? "block" : "none"}`,
                    }}
                    size={"1.5rem"}
                  />
                </div>
              ) : (
                <Typography
                  sx={{
                    color: getText().includes("No Results")
                      ? (theme) => theme.palette.text.disabled
                      : (theme) => theme.palette.text.primary,
                  }}
                  fontWeight="bold"
                  textAlign="center"
                  variant="body1"
                  noWrap
                >
                  {getText()}
                </Typography>
              )}
            </div>

            {searchValue !== "" ? (
              <Grid container>
                {userList.map((user, index) => (
                  <Grid
                    sx={{ pr: 3, pb: 3 }}
                    key={index}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                  >
                    <TeamUserCard onInvite={onInviteUser} item={user} />
                  </Grid>
                ))}
                {userList.length == 0 &&
                  !loading &&
                  isEmailFormat(searchValue) &&
                  searchValue !== dispatch_get_user_profile().email && (
                    <Grid sx={{ pr: 3, pb: 3 }} item xs={12} sm={6} md={3}>
                      <DummyUserCard
                        email={searchValue}
                        onInvite={onInviteUser}
                        isInvited={props?.workspace?.invitations?.some(
                          (inv) => inv.email == searchValue.trim()
                        )}
                      />
                    </Grid>
                  )}
              </Grid>
            ) : (
              <Grid container>
                <></>
              </Grid>
            )}
          </Stack>
        )}
        {inviteDrawerOpen && (
          <Grid container spacing={2.5}>
            <Grid item xs={12} display={"flex"}>
              <Typography fontWeight="bold" sx={{ mr: 1 }}>
                Member:
              </Typography>
              <Typography>
                {selectedUser.first_name
                  ? `${selectedUser.first_name} ${selectedUser.last_name}`
                  : selectedUser.email}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiAutoComplete
                value={workspaceAutoComplete}
                onChange={(val) => handleAutocompleteChange(val, "workspace")}
                // options={workspaces.filter(
                //   (workspace) =>
                //     workspace?.role == "admin" || workspace?.role == "manager"
                // )}
                options={[
                  ...workspaces.filter(
                    (workspace) =>
                      !workspace.invitations.some(
                        (inv) => inv.email == selectedUser?.email
                      ) &&
                      !workspace.team.some(
                        (member) => member.email == selectedUser?.email
                      )
                  ),
                ]}
                disabled={props?.workspace}
                label="Choose workspace*"
              />
            </Grid>
            {workspaceAutoComplete && (
              <Grid item xs={12} sm={6}>
                <MuiMultiAutocomplete
                  value={projectAutoComplete}
                  onChange={(val) => handleAutocompleteChange(val, "project")}
                  options={
                    projects.length > projectAutoComplete.length
                      ? [
                          { label: "Select all projects", value: "all" },
                          ...projects,
                        ]
                      : [...projects]
                  }
                  label="Choose project (optional)"
                  disabled={props?.project}
                />
              </Grid>
            )}
            {(!workspaceAutoComplete ||
              workspaceAutoComplete.role !== "client") && (
              <>
                <Grid item xs={12} sm={6}>
                  <MuiSelect
                    disabled={isClient}
                    onOptionClick={onRoleOptionClick}
                    options={ROLE_OPTIONS}
                    value={roleValue}
                    onChange={onChangeRole}
                    label="Choose Role"
                  />
                </Grid>
                {roleValue === "client" &&
                  workspaceAutoComplete &&
                  workspaceAutoComplete.role !== "client" && (
                    <Grid item xs={12} sm={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControl fullWidth>
                          <MuiMultiAutocomplete
                            sx={{ width: "100%" }}
                            value={membersAutoComplete}
                            onChange={(val) =>
                              handleAutocompleteChange(val, "member")
                            }
                            options={memberOptions}
                            label="Choose members (optional)"
                            fixedOptions={memberOptions.filter(
                              (member) =>
                                member.role == "admin" ||
                                member.user_id ==
                                  dispatch_get_user_profile().user_id
                            )}
                          />
                        </FormControl>
                        <Tooltip title="Select the team members that will be visible for the client, you cannot unselect the admin and if you are manager you also cannot unselect yourself">
                          <div>
                            <Iconify
                              icon="fe:info"
                              sx={{
                                width: "20px",
                                height: "20px",
                                ml: 1,
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </Tooltip>
                      </div>
                      {/* <FormControl>
                        <InputLabel htmlFor="outlined-adornment-amount">
                          Per hour rate(optional)
                        </InputLabel>
                        <OutlinedInput
                          value={perHourRate}
                          id="outlined-adornment-amount"
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                          label="Per hour rate(optional)"
                          onChange={(event) => {
                            const newValue = event.target.value;

                            if (/^$|^\d*\.?\d+$/.test(newValue)) {
                              setPerHourRate(newValue);
                            }
                          }}
                        />
                      </FormControl> */}
                    </Grid>
                  )}
                {(roleValue === "team" || roleValue === "client") && (
                  <Grid item xs={12}>
                    <Stack direction={"row"} spacing={5}>
                      {/* <Grid item xs={12} sm={6}> */}
                      <FormControl>
                        <FormLabel>Task Drag-Drop</FormLabel>
                        <RadioGroup
                          title="Drag-Drop"
                          row
                          disabled={sendingInv}
                          aria-labelledby="gender-select-radio-buttons-label"
                          name="row-radio-buttons-group"
                          value={dragValue}
                          onChange={handleChangeDrag}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Allow"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Disallow"
                          />
                        </RadioGroup>
                      </FormControl>
                      {/* </Grid>
                      <Grid item xs={12} sm={6}> */}
                      <FormControl>
                        <FormLabel>Add project</FormLabel>
                        <RadioGroup
                          title="Add-Project"
                          row
                          disabled={sendingInv}
                          aria-labelledby="gender-select-radio-buttons-label"
                          name="row-radio-buttons-group"
                          value={addProjValue}
                          onChange={handleChangeAddProj}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Allow"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Disallow"
                          />
                        </RadioGroup>
                      </FormControl>
                      {/* </Grid> */}
                    </Stack>
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12}>
              <div className="centered-row">
                <LoadingButton
                  disabled={workspaceDataLoading}
                  loading={sendingInv}
                  onClick={handleSendInvite}
                  variant="contained"
                >
                  Send Invite
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default InviteMembersDialog;
