import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { _add_planned_time } from "src/DAL/taskDetailDrawer";
import { LoadingButton } from "@mui/lab";

const timeOptions = [
  "30 min",
  "45 min",
  "1 hr",
  "1 hr 30 min",
  "2 hr",
  "2 hr 30 min",
  "3 hr",
  "4 hr",
];
// This Component is called in Timer and TimeBlock
export default function PlannedTimeDialog({
  userId,
  task,
  openPlannedTimeModal,
  setOpenPlannedTimeModal,
  plannedTime,
  setPlannedTime,
  getTaskDetail,
  handleStartPause, // only this prop is passed from Timer
}) {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const validatePlannedTime = (plannedTime) => {
    const timeRegex = /^([0-9]+):([0-5][0-9])$/;
    if (
      !plannedTime ||
      !timeRegex.test(plannedTime) ||
      plannedTime === "00:00"
    ) {
      enqueueSnackbar("Please enter a valid time (e.g., 01:30 HH:MM).", {
        variant: "warning",
      });
      return false;
    }
    return true;
  };

  const addPlannedTime = async () => {
    if (!validatePlannedTime(plannedTime)) {
      return;
    }
    setLoading(true);
    const req_obj = {
      task_id: task?._id,
      user_id: userId,
      planned_time: convertTimeToSeconds(plannedTime),
    };

    const response = await _add_planned_time(req_obj);
    if (response.code === 200) {
      setLoading(false);
      setOpenPlannedTimeModal(false);
      if (handleStartPause) {
        handleStartPause();
      } else {
        getTaskDetail();
        enqueueSnackbar("Planned time saved successfully!", {
          variant: "success",
        });
      }
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  // Autocomplete --------------------------------------------
  const convertTimeToSeconds = (time) => {
    if (!time) return 0;
    // format ("1 hour", "1 hr 30 min" to 01:30)
    const hourMatch = time.match(
      /^(\d{1,2})\s*(hr|hrs|hour|hours)\s*(\d{1,2})?\s*(min)?/
    );
    if (hourMatch) {
      const hours = parseInt(hourMatch[1], 10);
      const minutes = hourMatch[3] ? parseInt(hourMatch[3], 10) : 0;
      return hours * 3600 + minutes * 60;
    }

    // format ("45 min" to 00:45)
    const minuteMatch = time.match(/^(\d{1,2})\s*(min)/);
    if (minuteMatch) {
      const minutes = parseInt(minuteMatch[1], 10);
      return minutes * 60;
    }

    // format ("02:00" to 02:00)
    const timeMatch = time.match(/^(\d{1,2}):(\d{2})$/);
    if (timeMatch) {
      const hours = parseInt(timeMatch[1], 10);
      const minutes = parseInt(timeMatch[2], 10);
      return hours * 3600 + minutes * 60;
    }
    return 0;
  };
  const convertSecondsToHHmm = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <>
      <Dialog
        open={openPlannedTimeModal}
        onClose={() => setOpenPlannedTimeModal(false)}
      >
        <DialogTitle width={300}>Set Planned Time</DialogTitle>
        <DialogContent>
          <div className="mt-2">
            <Autocomplete
              size="small"
              options={timeOptions}
              value={plannedTime || ""}
              onChange={(event, selectedOption) => {
                if (!selectedOption) {
                  setPlannedTime("");
                } else {
                  const inSeconds = convertTimeToSeconds(selectedOption);
                  const hhmmFormat = convertSecondsToHHmm(inSeconds);
                  setPlannedTime(hhmmFormat);
                }
              }}
              renderOption={(props, option) => (
                <span {...props} style={{ fontWeight: "500" }}>
                  {option}
                </span>
              )}
              renderInput={(option) => (
                <TextField
                  {...option}
                  value={plannedTime}
                  label="Planned Time"
                  placeholder="hh:mm"
                  type="text"
                  fullWidth
                  inputProps={{
                    ...option.inputProps,
                    maxLength: 5,
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    const parts = value.split(":");
                    if (parts.length > 2) {
                      value = value.substring(0, value.lastIndexOf(":"));
                    }
                    if (value.length === 2 && !value.includes(":")) {
                      value += ":";
                    }
                    if (parts.length === 2) {
                      let hours = parts[0];
                      let minutes = parts[1];
                      if (parseInt(minutes, 10) > 59) {
                        minutes = "59";
                      }
                      value = `${hours}:${minutes}`;
                    }
                    if (value.endsWith(":") && value.length > 3) {
                      value = value.substring(0, value.length - 1);
                    }
                    setPlannedTime(value);
                  }}
                  // onChange={(e) => {
                  //   let value = e.target.value;
                  //   if (value.split(":").length > 2) {
                  //     value = value.substring(0, value.lastIndexOf(":"));
                  //   }
                  //   if (value.length === 2 && !value.includes(":")) {
                  //     value += ":";
                  //   }
                  //   if (value.length > 2 && !value.includes(":")) {
                  //     value = value.substring(0, 2) + ":" + value.substring(2);
                  //   }
                  //   // If there's an extra colon at the end (after deletion), remove it
                  //   if (value.endsWith(":") && value.length > 3) {
                  //     value = value.substring(0, value.length - 1);
                  //   }

                  //   setPlannedTime(value);
                  // }}
                  onKeyDown={(e) => {
                    const validKeys = [
                      "Backspace",
                      "ArrowLeft",
                      "ArrowRight",
                      "Tab",
                      "Enter",
                    ];
                    if (!validKeys.includes(e.key) && !/^[0-9]$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              )}
              freeSolo
              fullWidth
            />
          </div>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            color="primary"
            onClick={addPlannedTime}
          >
            Save
          </LoadingButton>
          <Button
            color="primary"
            onClick={() => setOpenPlannedTimeModal(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
