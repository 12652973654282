import React, { useState } from "react";
import { Box, TextField, InputAdornment, IconButton } from "@mui/material";
import { Iconify } from "src/components";

export default function CreateTaskInput({
  createTask,
  addingTask,
  setTaskField,
}) {
  const [createTaskInput, setCreateTaskInput] = useState("");

  const handleTaskInputChange = (event) => {
    setCreateTaskInput(event.target.value);
  };

  const handleTaskInputBlur = () => {
    if (createTaskInput === "") {
      setTaskField(false);
    }
  };

  const handleCreateTask = (event) => {
    event.preventDefault();
    setCreateTaskInput("");
    setTaskField(false);
    createTask(event,createTaskInput.trim());
  };

  return (
    <>
      <Box component="form" width="100%">
        <TextField
          value={createTaskInput}
          onChange={handleTaskInputChange}
          fullWidth
          autoComplete="current-password"
          label="Task"
          onKeyDown={(event) =>
            event.key === "Enter" ? handleCreateTask(event) : null
          }
          autoFocus
          onBlur={() => {
            handleTaskInputBlur();
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {addingTask ? (
                  <IconButton onClick={handleCreateTask} edge="end">
                    <Iconify icon="eos-icons:loading" />
                  </IconButton>
                ) : (
                  <>
                    {createTaskInput.trim() !== "" && (
                      <IconButton onClick={handleCreateTask} edge="end">
                        <Iconify icon="fluent:send-24-regular" />
                      </IconButton>
                    )}
                  </>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  );
}
