import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { CircularLoader } from "..";
import { useAppContext } from "src/hooks";
import ReactEditor from "react-text-editor-kit";
import { get_from_localStorage, invokeApi } from "src/utils";
import axios from "axios";
import { _upload_file } from "src/DAL";
import { s3baseUrl } from "src/config";

let editorImages = [];
export default function RichEditor({
  editorRef = null,
  value = "",
  setValue = () => {},
  disabled = false,
  onBlur = () => {},
  placeHolder = "",
  editorHeight = "",
}) {
  const { key, keyLoading } = useAppContext();
  const [isReady, setIsReady] = useState(false);
  const [focuse, setfocuse] = useState(false);
  const [initialValue, setInitialValue] = useState(value);
  const [imageUrl, setImageUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  // const [value, setValue] = useState("");
  const ref = useRef(null);

  const handleInit = (evt, editor) => {
    console.log("editor-ready");
    if (editorRef) {
      editorRef.current = editor;
    }
    setIsReady(true);
  };
  const handleOutsideClick = (event) => {
    // Check if the click is outside the TinyMCE editor toolbar
    const button = document.getElementsByClassName(
      "tox-tbtn tox-tbtn--enabled"
    )[0];
    const toolbar = document.getElementsByClassName("tox-toolbar__overflow")[0];
    if (
      toolbar &&
      !toolbar.contains(event.target) &&
      !button?.contains(event.target)
    ) {
      button.click();
    }
  };
  const handleUpdateImageWidth = (result, index) => {
    let width = result[index].image.getAttribute("width");
    let height = result[index].image.getAttribute("height");

    if (width) {
      if (width > 200) {
        height = height / (width / 100);
        result[index].image.style.height = `${height}px`;
        result[index].image.style.width = "100px";
      }
    } else {
      result[index].image.style.width = "100px";
    }
  };

  function handleEditorInit() {
    setTimeout(() => {
      let containerWarning = document.getElementsByClassName(
        "tox-notification--warning"
      );
      if (containerWarning.length > 0) {
        containerWarning[0].style.display = "none";
        setfocuse(false);
      }
      setfocuse(false);
    }, 100);
  }

  // const image_handler = async (file) => {
  //   setIsUploading(true);
  //   try {
  //     const formData = new FormData();
  //     formData.append("image", file);
  //     formData.append("width", "600");

  //     const response = await _upload_file(formData);

  //     if (response.code === 200) {
  //       console.log("Upload successful:", response);
  //       setImageUrl(response.path);
  //       return response.path;
  //     } else {
  //       console.error("Upload failed:", response);
  //       throw new Error("Upload failed");
  //     }
  //   } catch (error) {
  //     console.error("Error during upload:", error);
  //     throw error;
  //   } finally {
  //     setIsUploading(false);
  //   }
  // };
  // const image_handler = async (file) => {
  //   setIsUploading(true);
  //   try {
  //     const formData = new FormData();
  //     formData.append("directory", "images/");
  //     formData.append("upload_file", file);
  //     console.log(formData, "dataaaaaaaaaa");
  //     const response = await _upload_file(formData);
  //     if (response.code === 200) {
  //       console.log("Upload successful:", response);
  //       setImageUrl(response.path);
  //       return response.path;
  //     } else {
  //       console.error("Upload failed:", response);
  //       throw new Error(response.message || "Upload failed");
  //     }
  //   } catch (error) {
  //     console.error("Error during upload:", error);
  //     alert("Failed to upload image. Please try again.");
  //     return null;
  //   } finally {
  //     setIsUploading(false);
  //   }
  // };

  async function image_handler(file) {
    console.log(file, "inside image upoad function");
    let requestObj = {
      path: "api/app_api/uplaod_file_s3",
      method: "POST",
      headers: {
        "x-sh-auth": get_from_localStorage("token"),
      },
    };
    const formData = new FormData();
    formData.append("directory", "images/");
    formData.append("upload_file", file.image);
    requestObj["postData"] = formData;
    console.log(...formData, "dataaaaaaaaaaaa");

    try {
      let results = await invokeApi(requestObj);
      if (results.code === 200) {
        return s3baseUrl + results.path;
      } else {
        return "";
      }
    } catch (error) {
      return "";
    }

    // invokeApi(requestObj).then((res) => {
    //   console.log(res, "IMAGE UPLOADER");
    //   if (res.code === 200) {
    //     // success(res.path);
    //     if (typeof success === "function") {
    //       success(s3baseUrl + res.path);
    //     }
    //   } else {
    //     if (typeof failure === "function") {
    //       failure(res.message);
    //     }
    //   }
    // });
    // console.log(requestObj, "requestObj");
  }

  let theme_config = {
    "background-color": "#fff",
    "border-color": "#dcdcde",
    "text-color": "#414141",
    "toolbar-button-background": "#fff",
    "toolbar-text-color": "#414141",
    "toolbar-button-hover-background": "#efefef",
    "toolbar-button-selected-background": "#dee0e2",
    "svg-color": "#414141",
    "save-button-background": "rgb(9, 134, 62)",
  };

  const navbar = [
    {
      name: "file",
      // options: ["new_document", "preview", "print"],
      options: ["preview"],
    },
    {
      name: "view",
      title: "View",
      options: ["source_code", "full_screen"],
    },
    {
      name: "insert",
      title: "insert",
      options: ["image", "link", "video", "hr_line", "special_char"],
    },
    {
      name: "format",
      title: "format",
      options: [
        "bold",
        "italic",
        "underline",
        "superscript",
        "subscript",
        "font",
        "font_size",
        "alignment",
      ],
    },
    "|",
    "select_all",
    "|",
    "image",
    "link",
    "video",
    "|",
  ];

  const toolbar = [
    "undo",
    "redo",
    "|",
    "format",
    "|",
    "bold",
    "italic",
    "underline",
    "alignLeft",
    "alignCenter",
    "alignRight",
    "alignJustify",
    "orderedList",
    "unorderedList",
  ];

  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  const handlePaste = (e) => {
    if (e.clipboardData.files.length) {
      const fileObject = e.clipboardData.files[0];
      const file = {
        image: fileObject,
      };

      console.log(file, "--file");

      image_handler(file).then((result) => {
        console.log(result, "result");

        setValue((c) => {
          console.log(c, "old");
          var updated = "";
          if (c) {
            updated = c.replace(/blob:[^"]+/g, result);
          } else {
            updated = `<p><img src="${result}" alt="Image"></p>`;
          }
          console.log(updated, "updated");
          return updated;
        });
      });
    } else {
    }
  };

  useEffect(() => {
    handleEditorInit();
  }, [focuse]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setValue(initialValue ?? "");
    return () => setInitialValue("");
  }, [initialValue]);

  return (
    <div style={{ position: "relative" }} onPaste={handlePaste}>
      <ReactEditor
        value={value}
        onChange={(val) => {
          console.log(val, "--val ReactEditor");

          // fix break-line issue.
          const updated = val.replace(/&nbsp;/g, "<br>");
          // ---------------------------------
          setValue(updated);
        }}
        disabled={disabled}
        autoFocus={true}
        onBlur={onBlur}
        navbar={navbar}
        toolbar={toolbar}
        placeholder={placeHolder}
        theme_config={theme_config}
        style={{ height: editorHeight ? editorHeight : "100px" }}
        image_handler={image_handler}
      />
      {/* {isUploading && <div>Uploading image...</div>} */}
      {/* {!keyLoading && (
        <Editor
          apiKey={
            key == "" ? "zw8761rjy2pgn7r9xnkfgfc8xr08rml9j04bsf615sd5wzdd" : key
          }
          onInit={(evt, editor) => {
            handleInit(evt, editor);
          }}
          initialValue={initialValue}
          value={value}
          disabled={disabled}
          autoFocus={true}
          onBlur={onBlur}
          onClick={handleOutsideClick}
          onEditorChange={(newValue, editor) => {
            setValue(newValue);
            setfocuse(true);
          }}
          init={{
            height: 153,
            menubar: false,
            image_caption: true,
            resize: false,
            statusbar: false,
            // max_height: 600,
            min_height: 150,
            // width:auto,
            // images_file_types: "jpg",
            selector: "textarea",
            placeholder: placeHolder,
            // images_upload_handler: image_upload_handler,
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "link",
              "image",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "code",
              "autoresize",
              // 'help',
              // 'wordcount',
            ],
            toolbar:
              "blocks |" +
              "bold italic underline | forecolor backcolor |" +
              "strikethrough link image |" +
              "bullist numlist |undo redo |",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px } img {max-height:156px; max-width:326px; object-fit:contain}",

            //
            file_picker_callback: (cb, value, meta) => {
              const input = document.createElement("input");
              input.setAttribute("type", "file");
              input.setAttribute("accept", "image/*");
              input.addEventListener("change", (e) => {
                const file = e.target.files[0];

                const reader = new FileReader();
                reader.addEventListener("load", () => {
                  const id = "blobid" + new Date().getTime();
                  const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                  const base64 = reader.result.split(",")[1];
                  const blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);
                  cb(blobInfo.blobUri(), { title: file.name });
                });
                reader.readAsDataURL(file);
              });

              input.click();
            },
            setup: function (editor) {
              editor.on("BeforeSetLink", (e) => {
                e.target.rel = "noopener noreferrer"; // Add rel attribute for security reasons
              });
              editor.on("init", function (args) {
                editor = args.target;

                editor.on("NodeChange", function (e) {
                  if (e && e.element.nodeName.toLowerCase() == "img") {
                    // let width = e.element.width;
                    // let height = e.element.height;
                    // if (width > 200) {
                    //   height = height / (width / 100);
                    //   width = 100;
                    // }
                    // tinyMCE.DOM.setAttribs(e.element, {
                    //   width: width,
                    //   height: height,
                    // });
                  }
                });
                // editor.on("Drop", function (e) {
                //   if (e.dataTransfer && e.dataTransfer.files.length > 0) {
                //     const droppedFiles = e.dataTransfer.files;
                //     droppedFiles.map((file) => {
                //       if (file.includes("image/")) {
                //         const fileReader = new FileReader();

                //         fileReader.onload = function () {
                //           const img = new Image();
                //           img.onload = function () {
                //             // Manipulate the image dimensions here
                //             const desiredWidth = 200;
                //             const desiredHeight = 100;

                //             const canvas = document.createElement("canvas");
                //             canvas.width = desiredWidth;
                //             canvas.height = desiredHeight;
                //             const ctx = canvas.getContext("2d");
                //             ctx.drawImage(img, 0, 0, desiredWidth, desiredHeight);

                //             const manipulatedImageDataURL = canvas.toDataURL(); // Get the manipulated image as a data URL

                //             // Insert the manipulated image into the editor
                //             editor.insertContent(
                //               `<img src="${manipulatedImageDataURL}" alt="Dropped Image" />`
                //             );
                //           };

                //           img.src = fileReader.result;
                //         };

                //         fileReader.readAsDataURL(file); // Read the dropped image file as a data URL
                //       }
                //     });
                //   }
                //   // setTimeout(async () => {
                //   //   const result = await editor.editorUpload.scanForImages();
                //   //   console.log(result, "acaskcjaslckjaskcla", e);
                //   //   for (let i = 0; i < result.length; i++) {
                //   //     // for (let j = 0; j < editorImages.length; j++) {
                //   //     if (
                //   //       result[i].blobInfo.base64() ==
                //   //       editorImages[i]?.blobInfo?.base64()
                //   //     ) {
                //   //       console.log("Same Images sdjklfsdkfjklsd");
                //   //     } else {
                //   //       console.log("different Images sdjklfsdkfjklsd");

                //   //       handleUpdateImageWidth(result, i);
                //   //     }
                //   //     // }
                //   //   }
                //   //   editorImages = result;
                //   // }, 1);
                // });

                // editor.on("paste", async function (e) {
                //   setTimeout(async () => {
                //     const result = await editor.editorUpload.scanForImages();
                //     for (let i = 0; i < result.length; i++) {
                //       if (
                //         result[i].blobInfo.base64() ==
                //         editorImages[i]?.blobInfo?.base64()
                //       ) {
                //         console.log("Same Images sdjklfsdkfjklsd");
                //       } else {
                //         console.log("different Images sdjklfsdkfjklsd");

                //         handleUpdateImageWidth(result, i);
                //       }
                //     }
                //     editorImages = result;
                //   }, 1);
                // });
              });
              editor.on("focus", () => {
                setfocuse(true);
              });
            },
          }}
        />
      )}
      {(!isReady || keyLoading) && (
        <>
          <CircularLoader size="1.5rem" />
        </>
      )} */}
    </div>
  );
}
