import React, { useState, useEffect } from "react";
import ApexCharts from "react-apexcharts";
import { useTheme } from "@mui/material/styles";
import { _get_time_stats } from "src/DAL/dashboard";
import { get_month_first_day, get_month_last_day } from "src/utils";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useSnackbar } from "notistack";
import DayChartDialog from "./DayChartDialog";

export default function MonthlyTaskChart() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  // ----------------------------------------
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [timeStats, setTimeStats] = useState([]);
  const [chartData, setChartData] = useState({
    categories: [], // Months
    series: [], //  Duration
  });
  const [dayChartDialogOpen, setDayChartDialogOpen] = useState(false);

  const handleBarClick = (monthIndex) => {
    setSelectedMonth(monthIndex);
    setDayChartDialogOpen(true);
  };

  const chartOptions = {
    chart: {
      type: "bar",
      background: "transparent",
      toolbar: { show: false },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const selectedMonthIndex = config.dataPointIndex; 
          handleBarClick(selectedMonthIndex); 
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        columnWidth: "25%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: chartData.categories,
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
      axisBorder: {
        show: false, // Remove the border at the bottom
      },
      axisTicks: {
        show: false, // Remove the tick marks at the bottom
      },
    },
    colors: [theme.palette.primary.main], // bar color
    grid: {
      borderColor: "#E0E0E0",
      strokeDashArray: 2,
    },
    tooltip: {
      y: {
        formatter: (val) => {
          const hours = Math.floor(val / 3600);
          const minutes = Math.floor((val % 3600) / 60);
          return `${hours} hr ${minutes} min`;
        },
      },
      x: {
        formatter: (value) => {
          const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          const monthIndex = chartData.categories.indexOf(value);
          return monthNames[monthIndex]; // Return full month name
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => {
          const hours = Math.floor(val / 3600); // convert sec into hr
          return `${hours} h`;
        },
      },
    },
  };

  const sumDailyDuration = (time_Stats) => {
    const monthDuration = [];
    for (let i = 0; i < 12; i++) {
      monthDuration[i] = 0;
    }

    time_Stats.forEach((stats) => {
      if (stats.year === selectedYear) {
        stats.daily_stats.forEach((dailyStat) => {
          const date = new Date(dailyStat.date);
          const monthIndex = date.getMonth(); // Get the month index (0 = Jan, 11 = Dec)
          monthDuration[monthIndex] += dailyStat.duration;
        });
      }
    });

    setChartData({
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      series: [
        {
          name: "Duration",
          data: monthDuration,
        },
      ],
    });
  };

  const getTimeStatsData = async () => {
    // const first_day = get_month_first_day();
    // const last_day = get_month_last_day();
    const first_day = `${selectedYear}-01-01`;
    const last_day = `${selectedYear}-12-31`;
    const data = { start_date: first_day, end_date: last_day };

    try {
      const response = await _get_time_stats(data);
      if (response.code === 200) {
        console.log(response, "Task_Data_For_Chart");
        sumDailyDuration(response.time_stats);
        setTimeStats(response.time_stats);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  // years dropdown
  const generateYears = () => {
    const pastYear = selectedYear - 1;
    const nextYear = selectedYear + 1;
    return [pastYear, selectedYear, nextYear];
  };

  useEffect(() => {
    getTimeStatsData();
  }, [selectedYear]);

  return (
    <>
      <Card sx={{ boxShadow: 3 }}>
        <CardHeader
          title={
            <div className="d-flex align-item-center justify-content-between">
              <Typography fontWeight="bold" fontSize="25px">
                Time Stats
              </Typography>

              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel>Year</InputLabel>
                <Select
                  size="small"
                  value={selectedYear}
                  onChange={handleYearChange}
                  label="Year"
                >
                  {generateYears().map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          }
        />
        <CardContent sx={{ padding: "8px !important" }}>
          <ApexCharts
            options={chartOptions}
            series={chartData.series}
            type="bar"
            height={400}
          />
        </CardContent>
      </Card>
      {/* Daily stats chart --------------------------  */}
      <DayChartDialog
      dayChartDialogOpen={dayChartDialogOpen}
      setDayChartDialogOpen={setDayChartDialogOpen}
      timeStats={timeStats}
      selectedMonth={selectedMonth}
      selectedYear={selectedYear}
      />
    </>
  );
}
