import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import ApexCharts from "react-apexcharts";
import { useTheme } from "@mui/material/styles";
import { Iconify } from "src/components";

export default function DayChartDialog({
  dayChartDialogOpen,
  setDayChartDialogOpen,
  timeStats,
  selectedMonth,
  selectedYear,
}) {
  const theme = useTheme();
  const [chartData, setChartData] = useState({
    categories: [],
    series: [{ name: "Duration", data: [] }],
  });

  const chartOptions = {
    chart: {
      type: "bar",
      background: "transparent",
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        horizontal: false,
        columnWidth: "25%",
      },
    },
    dataLabels: { enabled: false },
    xaxis: {
      categories: chartData.categories,
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    colors: [theme.palette.primary.main],
    grid: {
      borderColor: "#E0E0E0",
      strokeDashArray: 2,
    },
    tooltip: {
      y: {
        formatter: (val) => {
          const hours = Math.floor(val / 3600);
          const minutes = Math.floor((val % 3600) / 60);
          return `${hours} hr ${minutes} min`;
        },
      },
      x: {
        formatter: (value) => {
          const date = new Date(selectedYear, selectedMonth, value);
          const dayName = new Intl.DateTimeFormat("en-US", {
            weekday: "long",
          }).format(date);
          //   return `${dayName}, ${value}-${getMonthName(selectedMonth)}`;
          return dayName;
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => {
          const hours = Math.floor(val / 3600);
          return `${hours} hr `;
        },
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  };

  const getMonthName = (monthIndex) => {
    const date = new Date();
    date.setMonth(monthIndex);
    return new Intl.DateTimeFormat("en-US", { month: "long" }).format(date);
  };

  useEffect(() => {
    if (dayChartDialogOpen) {
      const daysInMonth = new Date(
        selectedYear,
        selectedMonth + 1,
        0
      ).getDate();
      const durationData = Array(daysInMonth).fill(0);

      timeStats.forEach((stats) => {
        if (stats.year === selectedYear) {
          stats.daily_stats.forEach((dailyStat) => {
            const date = new Date(dailyStat.date);
            if (date.getMonth() === selectedMonth) {
              const dayIndex = date.getDate() - 1;
              durationData[dayIndex] = dailyStat.duration;
            }
          });
        }
      });

      const categories = [];
      for (let i = 1; i <= daysInMonth; i++) {
        console.log(i,"i = Date")
        categories.push(i.toString());
      }

      setChartData({
        categories: categories,
        series: [{ name: "Duration", data: durationData }],
      });
    }
  }, [dayChartDialogOpen, selectedMonth, selectedYear, timeStats]);

  return (
    <Dialog
      open={dayChartDialogOpen}
      onClose={() => setDayChartDialogOpen(false)}
      fullWidth
      maxWidth={false}
    >
      <DialogTitle className="d-flex align-items-center">
        <Iconify
          icon="simple-line-icons:calender"
          style={{ marginRight: "10px" }}
        />
        {`${getMonthName(selectedMonth)} ${selectedYear}`}
      </DialogTitle>
      <DialogContent sx={{ padding: "8px !important" }}>
        <ApexCharts
          options={chartOptions}
          series={chartData.series}
          type="bar"
          height={400}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDayChartDialogOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
