import React, { useState } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { Iconify } from "src/components";
import { _add_task_section } from "src/DAL";
import { useSnackbar } from "notistack";

function AddSection({
  projectDetail,
  getUpdatedSectionList,
  setAllFilteredTasksBySections,
  setFilteredSectionsData,
  setProjectDetail,
  slug,
  workspaceId
}) {
  const [addSectionDialogOpen, setAddSectionDialogOpen] = useState(false);
  const [newSectionName, setNewSectionName] = useState("");
  const [newSectionSprint, setNewSectionSprint] = useState({
    start_date_time: "",
    end_date_time: "",
  });
  const [addSectionLoading, setAddSectionLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();


  const createSection = async (e) => {
    e.preventDefault();
    try {
      if (!newSectionName.trim()) {
        enqueueSnackbar("Please add valid name for Section", {
          variant: "error",
        });
        return;
      } else if (
        newSectionSprint.start_date_time &&
        !newSectionSprint.end_date_time
      ) {
        enqueueSnackbar("End date is required with start date", {
          variant: "error",
        });
        return;
      } else if (
        !newSectionSprint.start_date_time &&
        newSectionSprint.end_date_time
      ) {
        enqueueSnackbar("Start date is required with end date", {
          variant: "error",
        });
        return;
      }

      setAddSectionLoading(true);
      const req_data = {
        section_title: newSectionName,
        section_status: true,
        section_type: "0",
        project_id: slug,
        workspace_id: workspaceId,
        ...newSectionSprint,
      };

      const result = await _add_task_section(req_data);
      if (result.code === 200) {
        const updateSections = (_data) => {
          let item = {
            ...result.section_detail,
            expanded: false,
            createdAt: new Date().toISOString(),
            creator: false,
            task: [],
            completed_task_count: 0,
          };
          let updated = Array.from(_data);
          updated.push(item);
          return getUpdatedSectionList(updated);
        };
        console.log(result, "add-section-res");
        enqueueSnackbar("Section Added Successfully", { variant: "success" });
        setNewSectionName("");
        setNewSectionSprint({
          start_date_time: "",
          end_date_time: "",
        });
        setAddSectionDialogOpen(false);

        setAllFilteredTasksBySections((prev) => {
          return {
            ...prev,
            [result.section_detail._id]: {
              total: result.section_detail.task_count,
              task_list: [],
              completed_task_count: 0,
              load_more_url: `/api/task_filter_list/${result.section_detail._id}?page=0&limit=15`,
            },
          };
        });
        setFilteredSectionsData((prev) => {
          return {
            ...prev,
            total: prev.total + 1,
            section_lists: updateSections(prev.section_lists),
          };
        });
        if (req_data.end_date_time && req_data.start_date_time) {
          setProjectDetail((prev) => {
            return {
              ...prev,
              all_sprint_count: prev.all_sprint_count + 1,
              in_progress_sprint_count: prev.in_progress_sprint_count + 1,
            };
          });
        }
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, "caught while adding section in project");
    } finally {
      setAddSectionLoading(false);  
    }
  };

  return (
    <>
      <Button
        onClick={() => setAddSectionDialogOpen(true)}
        className="btn-select"
        color="inherit"
        sx={{
          bgcolor: (theme) => theme.palette.action.hover,
          alignItems: "center",
          width: "100%",
          height: "100%",
          padding: "5px",
        }}
        endIcon={
          <Iconify
            sx={{
              color: (theme) => theme.palette.primary.main,
              height: "1rem",
              width: "1rem",
            }}
            icon="fluent:add-24-filled"
          />
        }
      >
        <Typography component="span" variant="subtitle2" fontSize="12px">
          Add Section
        </Typography>
      </Button>

      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={() => setAddSectionDialogOpen(false)}
        open={addSectionDialogOpen}
      >
        <DialogTitle sx={{ fontWeight: "700", fontSize: "14px" }}>
          Add Section
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              autoFocus
              sx={{ mt: 1 }}
              required
              value={newSectionName}
              onChange={(e) => setNewSectionName(e.target.value)}
              label="Section Name"
            />
          </FormControl>
          {(projectDetail.role === "admin" ||
            projectDetail.role === "manager") && (
            <Stack direction={"row"} spacing={2} sx={{ mt: 2 }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  PopperProps={{ placement: "auto" }}
                  label="Sprint Start Date"
                  inputFormat="DD-MMM-YYYY"
                  value={
                    newSectionSprint.start_date_time
                      ? moment(newSectionSprint.start_date_time)
                      : null
                  }
                  onChange={(date) =>
                    setNewSectionSprint((prev) => {
                      return {
                        ...prev,
                        start_date_time: date ? date.toISOString() : "",
                      };
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      name="startDate"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MMM-YYYY",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  PopperProps={{ placement: "auto" }}
                  label="Sprint End Date"
                  inputFormat="DD-MMM-YYYY"
                  value={
                    newSectionSprint.end_date_time
                      ? moment(newSectionSprint.end_date_time)
                      : null
                  }
                  onChange={(date) =>
                    setNewSectionSprint((prev) => {
                      return {
                        ...prev,
                        end_date_time: date
                          ? moment(
                              moment(date).format("MM/DD/YYYY") + " 23:59",
                              "MM/DD/YYYY HH:mm"
                            ).toISOString()
                          : "",
                      };
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      name="endDate"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MMM-YYYY",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddSectionDialogOpen(false)}>Cancel</Button>
          <LoadingButton onClick={createSection} loading={addSectionLoading}>
            Add
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddSection;
